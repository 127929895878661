import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[copy]'
})
export class CopyDirective {
  @Input('copy') textToCopy: string = '';

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    
    if (!this.textToCopy) {
      return;
    }

    const textarea = document.createElement('textarea');
    textarea.value = this.textToCopy;
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    
    document.body.appendChild(textarea);
    textarea.select();
    
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Не удалось скопировать текст:', err);
    }
    
    document.body.removeChild(textarea);
  }
}
