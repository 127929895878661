<div class="sidebar-menu" [ngClass]="{ 'sidebar-menu--no-margin': isPageShort, 'big-margin': !isPageShort }">
  <ng-container *ngFor="let item of sidebarMenuItems">
    <div
      *ngIf="isUserStaff ? true : item.isParticipantMenu"
      class="sidebar-menu__item-wrapper sidebar-menu__item-wrapper--first"
      [routerLink]="['/' + item.route]" (click)="resetHistoryEvent()"
    >
      <kp-svg
        size="22px"
        [key]="item.iconKey"
        [kpTooltip]="isPageShort && item.label"
        [color]="changeColorForMenuItemOnFocus(item)"
      ></kp-svg>

      <div *ngIf="!isPageShort" [style.color]="changeColorForMenuItemOnFocus(item)">
        {{ item.label }}
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="isCurrentPageProfile && hasPreviousPage"
    class="kp-text-button-s"
    [class.disabled-btn]="isProfileAvatarLoaded"
    (click)="goToPreviousPage()"
  >
    <kp-svg class="kp-text-button-s__icon" key="arrow" size="14px" rotateAngle="90deg"></kp-svg>

    <div class="kp-text-button-m">Назад</div>
  </div>
</div>
