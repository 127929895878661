import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    const date = new Date(value);
    const localDate = new Date(date.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
    
    const getMonthName = (month: number): string => {
      const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
      return months[month];
    };

    const timeZoneOffset = -localDate.getTimezoneOffset() / 60;
    const timeZoneString = `UTC${timeZoneOffset >= 0 ? '+' : ''}${timeZoneOffset}`;

    const day = localDate.getDate();
    const month = getMonthName(localDate.getMonth());
    const hours = localDate.getHours().toString().padStart(2, '0');
    const minutes = localDate.getMinutes().toString().padStart(2, '0');

    return `${day} ${month} в ${hours}:${minutes} (${timeZoneString})`;
  }

}
