import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'toast-action-event-client',
  templateUrl: './toast-action-event-client.component.html',
  styleUrls: ['./toast-action-event-client.component.scss']
})
export class ToastActionEventClientComponent implements OnChanges {
  @Input() message: string;
  @Input() maxWidth: string = '400px';
  public isVisible = false;
  private timeout: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['message'] && changes['message'].currentValue) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      
      this.isVisible = true;
      
      this.timeout = setTimeout(() => {
        this.isVisible = false;
      }, 4000);
    }
  }
}
